<!-- Start Header -->
<header id="mu-hero">
  <div class="container-fixed" style="margin-left:2%; margin-right:1% ;">
    <nav class="navbar navbar-expand-lg navbar-light mu-navbar">
      <!-- Text based logo -->
      <!--<a class="navbar-brand mu-logo" href="index.html"><span>STC</span></a>-->
      <!-- image based logo -->
         <a class="navbar-brand mu-logo" routerLink="/home" onclick="switchNavMenu('home')"><img src="/assets/images/stc_logo_design.png" alt="logo"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="fa fa-bars"></span>
      </button>
  
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto mu-navbar-nav">
          <li class="nav-item home">
            <a routerLink="/home" onclick="switchNavMenu('home')">Home</a>
          </li>
          <!-- <li class="nav-item dropdown about">
            <a class="dropdown-toggle" href="#" onclick="switchNavMenu('about');applymenucss();"  role="button" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About Us</a>
           <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/about" onclick="switchNavMenu('about');applymenucss();">Company Profile</a>
              <a class="dropdown-item" routerLink="/staff" onclick="switchNavMenu('about');applymenucss();">Our Team</a>
            </div>
          </li> -->
          <li class="nav-item about"><a routerLink="/about" onclick="switchNavMenu('about')">About Us</a></li>
          <li class="nav-item services"><a routerLink="/services" onclick="switchNavMenu('services')">Services</a></li>
          <li class="nav-item clients"><a routerLink="/clients" onclick="switchNavMenu('clients')">Our Partners</a></li>
          <li class="nav-item division"><a routerLink="/division" onclick="switchNavMenu('division')">Divisions</a></li>
           <!--<li class="nav-item dropdown">
              <a class="dropdown-toggle" href="blog.html" role="button" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Divisions</a>
             <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" href="blog.html">Medical and Scientific Equipment Division</a>
                <a class="dropdown-item" href="blog-single.html">Laboratory supplies Division</a>
                <a class="dropdown-item" href="blog-single.html">IVF Assisted Reproduction Division</a>
                <a class="dropdown-item" href="blog-single.html">Logistics & Warehousing Division</a>
              </div>
          </li>-->
          <li class="nav-item career"><a routerLink="/career" onclick="switchNavMenu('career')">Career</a></li>
          <li class="nav-item contact"><a routerLink="/contact" onclick="switchNavMenu('contact')">Contact us</a></li>
        </ul>
      </div>
    </nav>
  </div>
  </header>
  <!-- End Header -->

  <!--START SCROLL TOP BUTTON -->
<a class="scrollToTop" href="#">
  <i class="fa fa-angle-up"></i>
</a>
<!-- END SCROLL TOP BUTTON -->

