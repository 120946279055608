import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MailerComponent } from "../mailer/mailer.component";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

declare var bootbox:any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  constructor(public mailer: MailerComponent,private router:Router,private spinner: NgxSpinnerService) { }
  

  formdata;
  nameControl;
  emailControl;
  subjectControl;
  messageControl;

  ngOnInit(): void {
    this.formdata = new FormGroup({
        name: this.nameControl =  new FormControl("",[
             Validators.required
           ]),
        email: this.emailControl = new FormControl("",[
          Validators.required,
          Validators.email
        ]),
        subject: this.subjectControl = new FormControl("",[
          Validators.required
        ]),
        message: this.messageControl = new FormControl("",[
          Validators.required
        ])
     });
  }

  // emailFormControl = new FormControl("", [
  //   Validators.required,
  //   Validators.email
  // ]);

  // nameFormControl = new FormControl("", [
  //   Validators.required
  // ]);

  // subjectFormControl = new FormControl("", [
  //   Validators.required
  // ]);

  // messageFormControl = new FormControl("", [
  //   Validators.required
  // ]);


  sendContactMail(data) {
    if(this.formdata.valid){
      this.spinner.show();
      let contact = {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        selectOpt: 'contact'
      }
      var prtcl = window.location.href;
      var prtclArr = prtcl.split(":");
      this.mailer.sendEmail(prtclArr[0]+"://stc-kw.com/app/sendmail", contact).subscribe(
        data => {
          let res:any = data;
          this.spinner.hide();
          //alert("Message Sent Successfully"); 
          bootbox.alert({
            message: "Message Sent Successfully!"
          });
          this.formdata.reset();
          //this.router.navigate(['/home']); 
        },
        err => {
          console.log(err);
        });
    }else{
      return;
    }
  }

}
