<input type="hidden" id="menuSelect" value="home">  
  <!-- Start slider area -->
  <div id="mu-slider">
    <div class="mu-slide">
      <!-- Start single slide  -->
      <div class="mu-single-slide">
        <img src="/assets/images/slider-img-1.jpg" alt="slider img">
        <div class="mu-single-slide-content-area">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="mu-single-slide-content">
                  <h1>Welcome to STC</h1>
                  <p>We are a part of a group of companies with successful businesses running in the fields of Medical educations, consultation for new Kuwait medical city.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End single slide  -->
    
      <!-- Start single slide  -->
      <div class="mu-single-slide">
        <img src="/assets/images/slider-img-2.jpg" alt="slider img">
        <div class="mu-single-slide-content-area">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="mu-single-slide-content">
                  <h1>We Fulfill medical needs</h1>
                  <p>We provide comprehensive hospital equipment and supplies with product priorities in Surgical, CSSD, laboratory medicine, IVF and Diagnostics.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End single slide  -->
    
      <!-- Start single slide  -->
      <div class="mu-single-slide">
        <img src="/assets/images/slider-img-3.jpg" alt="slider img">
        <div class="mu-single-slide-content-area">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="mu-single-slide-content">
                  <h1>Pioneer in medical science</h1>
                  <p>We are committed to our customer satisfaction by providing state of the art and quality healthcare services and supplies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End single slide  -->
    </div>
    </div>
    <!-- End Slider area -->
    
    
    <!-- Start main content -->
    <main>
    <!-- Start About -->
    <section id="mu-about">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mu-about-area">
              <!-- Title -->
              <div class="row">
                <div class="col-md-12">
                  <div class="mu-title">
                    <h2>Who we are</h2>
                    <p>Shams Trading Company (STC) is a Kuwaiti Limited Liability company for medical equipment and supplies that is run by a team of health care professionals.</p>
                  </div>
                </div>
              </div>
              <!-- Start Feature Content -->
              <div class="row">
                <div class="col-md-6">
                  <div class="mu-about-left">
                    <img class="" src="/assets/images/aboutus.jpg" alt="img">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mu-about-right">
                    <ul>
                      <li>
                        <h3>Our Mission</h3>
                        <p class="alltext-align">To become an essential contributor to healthcare development in the region, implementing and providing tomorrow’s technology in today’s life and allowing our successors live a better life by supplying the world class emerging medical technologies and health care services to our clients.</p>
                      </li>
                      <li>
                        <h3>Our Vision</h3>
                        <p class="alltext-align">To be the distributors of choice for globally reputed principals, and provide cost effective medical technology & healthcare services of international quality standards. <span class="seperator-class">|</span>
                           To constantly offer the Medical & Healthcare service market in Kuwait with its needs and requirements. <span class="seperator-class">|</span>
                           To deliver quality services by which we participate in the improvement of Kuwait ‘s healthcare. <span class="seperator-class">|</span>
                           To build rewarding relationships with our customers and business partners offering them with full satisfaction.
                        </p>
                      </li>
                      <li>
                        <h3>Our Values</h3>
                        <p class="alltext-align">Deliver niche products & services. <span class="seperator-class">|</span>
                           Address unsatisfied market need. <span class="seperator-class">|</span>
                           Respect for values. <span class="seperator-class">|</span>
                           Quest for excellence. <span class="seperator-class">|</span>
                           Compassion and sensitivity toward individuals. <span class="seperator-class">|</span>
                           Commitment to professionalism and ethics. <span class="seperator-class">|</span>
                           Achieve goals through Teamwork 
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- End Feature Content -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About -->

    <!-- Start Pricing Table -->
    <section id="mu-pricing">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mu-pricing-area">
              <!-- Title -->
              <div class="row">
                <div class="col-md-12">
                  <div class="mu-title">
                    <h2>What we offer</h2>
                    <p>Importing, marketing and sales of healthcare complete solutions and a wide range of hospital, medical & diagnostic equipment and supplies.</p>
                    <p>Excellent round-the-clock after-sales service and maintenance</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mu-pricing-content">
                    <ul class="mu-pricing-table">
                      <li>
                        <div class="mu-pricing-single">
                          <div class="mu-pricing-single-icon">
                            <span class="fa fa-heartbeat"></span>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Surgery</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Cardiology</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Neurosurgery</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Orthopedics</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Dermatology</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Gynecology</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Dialysis</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Dental</h4>
                          </div>
                          <!-- <div class="mu-pricing-single-content">
                            <ul>
                              <li>Cardiology</li>
                              <li>Neurosurgery</li>
                              <li>Orthopedics</li>
                              <li>Dermatology</li>
                              <li>Gynecology</li>
                              <li>Dialysis</li>
                              <li>Dental</li>
                            </ul>
                          </div> -->
                          <!--<div class="mu-single-pricebox">
                            <h4>29$<span>/month</span></h4>
                          </div>
                          <a class="mu-buy-now-btn" href="#">Buy now</a>-->
                        </div>
                      </li>
                      <li>
                          <div class="mu-pricing-single">
                            <div class="mu-pricing-single-icon">
                              <span class="fa fa-stethoscope"></span>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Urology</h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>ICU</h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Anesthesia</h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Pediatrics
                                </h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Ophthalmology
                                </h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Gastroenterology
                                </h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Neurology</h4>
                            </div>
                            <div class="mu-pricing-single-title">
                              <h4>Morgue</h4>
                            </div>
                            <!-- <div class="mu-pricing-single-content">
                              <ul>
                                <li>ICU</li>
                                <li>Anesthesia</li>
                                <li>Pediatrics</li>
                                <li>Ophthalmology</li>
                                <li>Gastroenterology</li>
                                <li>Neurology</li>
                                <li>Morgue</li>
                              </ul>
                            </div> -->
                            <!--<div class="mu-single-pricebox">
                              <h4>29$<span>/month</span></h4>
                            </div>
                            <a class="mu-buy-now-btn" href="#">Buy now</a>-->
                          </div>
                      </li>
                      <li>
                        <div class="mu-pricing-single">
                          <div class="mu-pricing-single-icon">
                            <span class="fa fa-medkit"></span>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Laboratory</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>IVF</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Medical Imaging</h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>
                              ENT
                              </h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Wound Care
                              </h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Oncology
                              </h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>CSSD
                              </h4>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h4>Turnkey Projects</h4>
                          </div>
                          
                          <!-- <div class="mu-pricing-single-content">
                            <ul>
                              <li>ICU</li>
                              <li>Medical Imaging</li>
                              <li>ENT</li>
                              <li>Wound Care</li>
                              <li>Oncology</li>
                              <li>CSSD</li>
                              <li>Turnkey Projects</li>
                            </ul>
                          </div> -->
                          <!--<div class="mu-single-pricebox">
                            <h4>29$<span>/month</span></h4>
                          </div>
                          <a class="mu-buy-now-btn" href="#">Buy now</a>-->
                        </div>
                      </li>
                      <!-- <li>
                        <div class="mu-pricing-single">
                          <div class="mu-pricing-single-icon">
                            <span class="fa fa-medkit"></span>
                          </div>
                          <div class="mu-pricing-single-title">
                            <h3>Services</h3>
                          </div>
                          <div class="mu-pricing-single-content">
                            <ul>
                              <li><span class="seperator-class">></span> Importing, marketing and sales of healthcare complete solutions and a wide range of hospital, medical & diagnostic equipment and supplies.</li>
                              <li><br></li>
                              <li><span class="seperator-class">></span> Excellent round-the-clock after-sales service and maintenance.</li>
                            </ul>
                          </div>
                          <div class="mu-single-pricebox">
                            <h4>29$<span>/month</span></h4>
                          </div>
                          <a class="mu-buy-now-btn" href="#">Buy now</a>
                        </div>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Pricing Table -->
    
    <!-- Start Client Testimonials -->
    <section id="mu-testimonials">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="mu-testimonials-area">
              <h2>Who we work with</h2>
    
              <div class="mu-testimonials-block">
                <ul class="mu-testimonial-slide">
                  

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/3BLogo_Name.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Educational appliances, models and accessories</p>
                    <h5 class="mu-rt-name" style="font-family: Calibri;">3B SCIENTIFIC</h5>
                    <span class="mu-rt-title">GERMANY</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/birr.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Consumables for IVF Laboratory</p>
                    <h5 class="mu-rt-name">BIRR BIOSCIENCES</h5>
                    <span class="mu-rt-title">NETHERLANDS</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/careofsweden.svg" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Medical Mattress for Pressure Ulcers</p>
                    <h5 class="mu-rt-name">CARE of SWEDEN</h5>
                    <span class="mu-rt-title">SWEDEN</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/cellvu.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>IVF CELL-VU Disposable Sperm Counting</p>
                    <h5 class="mu-rt-name">MILLENNIUM SCIENCES</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/esco.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Equipment for IVF Laboratory</p>
                    <h5 class="mu-rt-name">ESCO</h5>
                    <span class="mu-rt-title">DENMARK</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/euromex.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Laboratory and Educational Microscopes</p>
                    <h5 class="mu-rt-name">EUROMEX MICROSCOPES</h5>
                    <span class="mu-rt-title">NETHERLANDS</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/emedical.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Single Use Products for ICU and NICU</p>
                    <h5 class="mu-rt-name">EXCELLENTCARE MEDICAL</h5>
                    <span class="mu-rt-title">U.K</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/rt.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Blanco Medical Furniture</p>
                    <h5 class="mu-rt-name">FF Fémfeldolgozó Zrt.</h5>
                    <span class="mu-rt-title">HUNGARY </span>
                  </li>
  
                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/gainternational.svg" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Medical Labels and Tapes with a liner</p>
                    <h5 class="mu-rt-name">GA INTERNATIONAL</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/hunterscientific.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>IVF Equipment and Consumables</p>
                    <h5 class="mu-rt-name">HUNTER SCIENTIFIC</h5>
                    <span class="mu-rt-title">U.K</span>
                  </li>
    
                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/implantech.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Implant Materials for Plastic & CMF Surgery</p>
                    <h5 class="mu-rt-name">IMPLANTECH</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" style="width: 120px;" src="/assets/images/clientLogos/invidia.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Endoscopic Equipment and Instruments</p>
                    <h5 class="mu-rt-name">INVIDIA MEDICAL</h5>
                    <span class="mu-rt-title">GERMANY</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/kanmed.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Warming Systems and Neonatal Equipment</p>
                    <h5 class="mu-rt-name">KANMED</h5>
                    <span class="mu-rt-title">SWEDEN</span>
                  </li>
  
                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/lifeinstruments.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Spine and Orthopedic instruments</p>
                    <h5 class="mu-rt-name">LIFE INSTRUMENTS</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/mesi.svg" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>ECG & Blood pressure monitoring equipment</p>
                    <h5 class="mu-rt-name">MESI, Ltd</h5>
                    <span class="mu-rt-title">SLOVENIA</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/micromed.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Electrosurgical units and HF accessories</p>
                    <h5 class="mu-rt-name">MICROMED</h5>
                    <span class="mu-rt-title">GERMANY</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/nico.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Brain Path system</p>
                    <h5 class="mu-rt-name">NICO CORPORATION</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/nitrocare.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Hospital furnitures, patient beds and Stretchers</p>
                    <h5 class="mu-rt-name">NITROCARE</h5>
                    <span class="mu-rt-title">TURKEY</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/orthoselect.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Orthopedics Instruments</p>
                    <h5 class="mu-rt-name">ORTHO-SELECT</h5>
                    <span class="mu-rt-title">GERMANY</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/qualiteam.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Single Use Products for Cardiac Surgery</p>
                    <h5 class="mu-rt-name">QUALITEAM</h5>
                    <span class="mu-rt-title">ITALY</span>
                  </li>
    
                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/sechrist.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Hyperbaric Chamber and Neonatal Ventilators</p>
                    <h5 class="mu-rt-name">SECHRIST</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/simex.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Negative Pressure Wound Therapy System</p>
                    <h5 class="mu-rt-name">SIMEX </h5>
                    <span class="mu-rt-title">GERMANY</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/soring.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Ultrasonic Therapy and Electrosurgical units</p>
                    <h5 class="mu-rt-name">SOERING</h5>
                    <span class="mu-rt-title">GERMANY</span>
                  </li>
    
                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/stryker.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Operating Tables and Surgical lights</p>
                    <h5 class="mu-rt-name">STRYKER</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/thomson.svg" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Special Retractors for All type of Surgeries</p>
                    <h5 class="mu-rt-name">THOMPSON SURGICAL</h5>
                    <span class="mu-rt-title">U.S.A</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/unique.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Multiplace Hyperbaric Chambers</p>
                    <h5 class="mu-rt-name">UNIQUE MEDICAL</h5>
                    <span class="mu-rt-title">NETHERLANDS</span>
                  </li>

                  <li>
                    <img class="mu-rt-img" src="/assets/images/clientLogos/ysymedical.png" alt="img">
                    <h5 class="mu-rt-name">Products:</h5>
                    <p>Physiotherapy Equipment</p>
                    <h5 class="mu-rt-name">YSY MEDICAL</h5>
                    <span class="mu-rt-title">FRANCE</span>
                  </li>
    
                </ul>
              </div>
    
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Client Testimonials -->
  
      
    </main>
    
    <!-- End main content -->
  