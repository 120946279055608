<!-- Start footer -->
<footer id="mu-footer">
  <div class="mu-footer-top">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="mu-single-footer">
            <img class="mu-footer-logo" src="/assets/images/stc_footer_logo.png" alt="logo">
            <!-- <h1>STC</h1> -->
            <div class="subtext-logo">Shams Trading Company</div>
            <p>Shams Trading Company (STC) is a Kuwaiti Limited Liability company for medical equipment and supplies that is run by a team of health care professionals.</p>
            <div class="mu-social-media">
              <a href="https://www.facebook.com/STC-Medical-Kuwait-101043271636953/" target="_blank"><i class="fa fa-facebook"></i></a>
              <!-- <a class="mu-twitter" href="#"><i class="fa fa-twitter"></i></a> -->
              <a class="mu-instagram" href="https://instagram.com/stc_medical_kuwait?igshid=1e5eapofl2fyk" target="_blank"><i class="fa fa-instagram"></i></a>
              <!-- <a class="mu-google-plus" href="#"><i class="fa fa-google-plus"></i></a>
              <a class="mu-youtube" href="#"><i class="fa fa-youtube"></i></a> -->
            </div>
          </div>
        </div> 
        <div class="col-md-3">
          <div class="mu-single-footer">
            <h3>Useful links</h3>
            <ul class="mu-useful-links">
              <li><a routerLink="/services" onclick="switchNavMenu('services')">Service</a></li>
              <li><a routerLink="/division" onclick="switchNavMenu('division')">Divisions</a></li>
              <li><a routerLink="/clients" onclick="switchNavMenu('clients')">Partners</a></li>
              <li><a routerLink="/career" onclick="switchNavMenu('career')">Career</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-5">
          <div class="mu-single-footer">
            <h3>Contact Information</h3>
            <ul class="list-unstyled">
              <li class="media">
                <span class="fa fa-home"></span>
                <div class="media-body calibri-font">
                  <p>
                  Kuwait, Al Qibla-Block 11-Al Salhiya Street-Building No. 9
                  </p>
                  <p>
                  P.O. Box 4702 Salmiya 22048 Kuwait.
                  </p>
                  <p>Kuwait City</p>
                  <p>State of Kuwait</p>
                </div>
              </li>
              <li class="media">
                <span class="fa fa-phone"></span>
                <div class="media-body calibri-font">
                  <p>Tel  : + 965 22464561</p>
                  <p>Mobile  : + 965 99434076 </p>
                </div>
              </li>
              <li class="media">
                <span class="fa fa-fax"></span>
                <div class="media-body calibri-font">
                  <p>Fax  : + 965 22464568 </p>
                </div>
              </li>
              <li class="media">
                <span class="fa fa-envelope"></span>
                <div class="media-body calibri-font">
                  <p>Email  : info@stc-kw.com </p>
                  <p>Website : www.stc-kw.com</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mu-footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="mu-footer-bottom-area">
            <p class="mu-copy-right">&copy; Copyright <a rel="nofollow" href="http://www.epizo-solutions.com">Epizo Solutions</a>. All right reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </footer>
  <!-- End footer -->