<input type="hidden" id="menuSelect" value="contact">
<!-- Start main content -->
<main>
    <!-- Start Contact -->
    <section id="mu-contact">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mu-contact-area">
                        <!-- Title -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mu-title">
                                    <h2>Contact Us</h2>
                                    <p>Want to get in touch? Drop us a message.</p>
                                </div>
                                <div class="mu-title">
                                    <h2 style="text-transform: none;">Mamdouh Shams</h2>
                                    <p>Managing Director</p>
                                    <h4>
                                        Shams Trading Company for Medical Equipment & Supplies W.L.L.
                                    </h4>
                                </div>
                            </div>
                        </div>
                            <!-- Start Contact Content -->
                        <div class="mu-contact-content">
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="mu-contact-form-area">
                                        <div id="form-messages"></div>
                                        <form [formGroup] = "formdata" (ngSubmit) = "sendContactMail(formdata.value)" class="mu-contact-form">

                                            <div class="form-group" style="text-align: left;">  
                                                <span class="fa fa-user mu-contact-icon"></span>              
                                                <input type="text" class="form-control" placeholder="Name" id="name" name="name" formControlName="name" required><span style="color:red;font-size:small;" *ngIf="nameControl.invalid && nameControl.touched">*Name is required.</span>
                                            </div>

                                            <div class="form-group" style="text-align: left;" >  
                                                <span class="fa fa-envelope mu-contact-icon"></span>              
                                                <input type="email" class="form-control" placeholder="Enter Email" id="email" formControlName="email" name="email" required><span style="color:red;font-size:small;" *ngIf="emailControl.invalid && emailControl.touched">*Valid Email is required.</span>
                                            </div>    

                                            <div class="form-group" style="text-align: left;"> 
                                                <span class="fa fa-folder-open-o mu-contact-icon"></span>                
                                                <input type="text" class="form-control" placeholder="Your Subject" id="subject" name="subject" formControlName="subject" required><span style="color:red;font-size:small;" *ngIf="subjectControl.invalid && subjectControl.touched">*Subject is required.</span>
                                            </div>

                                            <div class="form-group" style="text-align: left;">
                                                <span class="fa fa-pencil-square-o mu-contact-icon"></span> 
                                                <textarea class="form-control" placeholder="Message" id="message" name="message" formControlName="message" required></textarea><span style="color:red;font-size:small;" *ngIf="messageControl.invalid && messageControl.touched">*Message is required.</span>
                                            </div>
                                            <button type="submit" class="mu-send-msg-btn"><span>Send Message</span></button>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- End Contact Content -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Contact -->

    <!-- Google map -->
    <div id="mu-google-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3477.044485060132!2d47.970295314576994!3d29.368986657119006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf84e81e6c15eb%3A0xa597009f171e2179!2sAl%20Luhaib%20Complex%2C%20Al%20Kuwayt%2C%20Kuwait!5e0!3m2!1sen!2sin!4v1590488741909!5m2!1sen!2sin" width="850" height="400" allowfullscreen></iframe>
    </div>


</main>

<!-- End main content -->