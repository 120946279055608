<input type="hidden" id="menuSelect" value="about">
<!-- Start Page Header area -->
<div id="mu-page-header" style="background-image: url(/assets/images/about-us-header-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mu-page-header-area">
                    <h1 class="mu-page-header-title">Company profile</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Header area -->


<!-- Start main content -->
<main>
    <!-- Start About -->
    <section id="mu-about">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mu-about-area">
                        <!-- Title -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mu-title-about">
                                    <h2>Who we are</h2>
                                    <p class="mu-about-p">STC Shams Trading Company for Medical Equipment & supplies is a Kuwaiti Limited Liability Company with a team of health care professionals. The team combines overall experience, knowledge and proven expertise with creative and innovative leadership skills in the healthcare industry. The company was established in August 2017 with commercial registration number 378490. STC is a part of a group of companies with successful businesses running in the fields of Medical educations, consultation for new Kuwait medical city. The company is engaged in two major division Medical technology Supplies and Healthcare Services and it has strong competitive advantage in the form of its contacts and experience in the market. STC provides comprehensive hospital equipment and supplies with product priorities in Surgical, CSSD, laboratory medicine, IVF and Diagnostics. We are also interested in supplying Wound care and Derma products and Pharmaceuticals to Ministry of Health (MOH) and private sectors throughout the State of Kuwait.  We believe in acquiring business expertise, strategic alliances, and quality client relationships for our organization through honesty and integrity. We are committed to our customer satisfaction by providing state of the art and quality healthcare services and supplies. We have a strong team of very experienced sales, service and project staff who efficiently work hand in hand for both the private and the public sector hospitals. Based on the feedback from our customers and after undergoing a thorough market study within the medical retail sector.</p>
                                </div>
                            </div>
                        </div>
                        <!-- Start Feature Content -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mu-about-left">
                                    <img class="" src="assets/images/aboutus.jpg" alt="img">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mu-about-right">
                                    <ul>
                                        <li>
                                          <h3>Our Mission</h3>
                                          <p class="alltext-align">To become an essential contributor to healthcare development in the region, implementing and providing tomorrow’s technology in today’s life and allowing our successors live a better life by supplying the world class emerging medical technologies and health care services to our clients.</p>
                                        </li>
                                        <li>
                                          <h3>Our Vision</h3>
                                          <p class="alltext-align">To be the distributors of choice for globally reputed principals, and provide cost effective medical technology & healthcare services of international quality standards. <span class="seperator-class">|</span>
                                             To constantly offer the Medical & Healthcare service market in Kuwait with its needs and requirements. <span class="seperator-class">|</span>
                                             To deliver quality services by which we participate in the improvement of Kuwait ‘s healthcare. <span class="seperator-class">|</span>
                                             To build rewarding relationships with our customers and business partners offering them with full satisfaction.
                                          </p>
                                        </li>
                                        <li>
                                          <h3>Our Values</h3>
                                          <p class="alltext-align">Deliver niche products & services. <span class="seperator-class">|</span>
                                             Address unsatisfied market need. <span class="seperator-class">|</span>
                                             Respect for values. <span class="seperator-class">|</span>
                                             Quest for excellence. <span class="seperator-class">|</span>
                                             Compassion and sensitivity toward individuals. <span class="seperator-class">|</span>
                                             Commitment to professionalism and ethics. <span class="seperator-class">|</span>
                                             Achieve goals through Teamwork 
                                          </p>
                                        </li>
                                      </ul>
                                </div>
                            </div>
                        </div>
                        <!-- End Feature Content -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End About -->

    <!-- Start Goals -->
<div class="row goal-content-row">
    <div class="col-md-12 goal-content-col">
    <div class="goal-heading">
    Goals & Objectives
    </div>
    <div class="goal-subtext">
    <p>
    <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;  
    Develop our resources in accordance with our mission and vision statements
    </p>
    <p>
    <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;
    Create market demand for our products through innovation in terms of functionality, quality, reliability,
    cost and after-sales services
    </p>
    <p>
    <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;
    Develop competitive advantage over competitors’ Strategies and resources
    </p>
    <p>
    <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;
    Create market share for our supplies & services through our marketing and pricing strategies
    </p>
    <p>
    <span><img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;</span>
    <span>To utilize process improvement techniques to continually monitor, assess and improve the processes
    and systems within our services and supplies</span>
    </p>
    <p>
    <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;
    Ensure staff expertise and competency
    </p>
    <p>
    <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='25'> &nbsp;
    Expand our strategic resources
    </p>
    </div>
    
    </div>
    </div>
    <!-- End Goals -->


    <!-- Start division 1 -->
    <div class="staff-content">
        <div class="row">
            <div class="col-md-12">
                <div class="icon-div">
                    <img class="img-responsive" src="/assets/images/staff/ourstaff.png" alt="image" width='200'>
                </div>
                <div class="staff-heading">
                Our Staffs
                </div>
                <div class="staff-subtext">
                <p>
                STC is staffed with professional resources with diversified backgrounds :  
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Medical 
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Biomedical Engineering 
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Sales & Product Representatives 
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Technical & Application Specialists  
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Administration  
                </p>
                <p>
                Extensive market knowledge and network of relations with key decision makers in the healthcare industry
                </p>
                <p>
                Our staff receives training and orientation about STC Policies & Procedures, Business Ethics Manual, 
                Job Expectations, Sales, and Customer Relationship Management. In addition to staff training on new products technologies
                </p>
                <p>
                Continuously follow-up business files, orders, and visits to end-users. 
                </p>
                </div>
                
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                
                <div class="icon-div">
                    <img class="img-responsive" src="/assets/images/staff/sales_icon.png" alt="image" width='100'>
                </div>
                <div class="staff-heading">
                Sales Department
                </div>
                <div class="staff-subtext">
                <p>
                Our sales department is run by experienced and qualified medical and scientific specialists who are well aware of market 
                requirements and maintain excellent customer 
                relations to promote the different medical, laboratory and scientific products of the companies we represent exclusively 
                </p>
                </div>
                
                
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <div class="icon-div">
                    <img class="img-responsive" src="/assets/images/staff/service_icon.png" alt="image" width='100'>
                </div>
                <div class="staff-heading">
                Technical Service Centre
                </div>
                <div class="staff-subtext">
                <p>
                We have fully equipped technical service centre for after sales services 
                which is run by our well qualified, experienced and trained Biomedical service engineers
                </p>
                <p>
                We would like to clarify that Kuwait is geographically small and the demand in terms of quantities 
                are limited. As it is shown on the following list, we are representing as many companies in order to 
                offer complete systems and solutions to our customers
                </p>
                <p>
                We are the accredited exclusive agent for many world-renowned companies for their various products
                </p>
                </div>
                
            </div>
        </div>

    </div>
    <!-- End division 1 -->



    <!-- Start Clients -->
    <div id="mu-clients">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mu-clients-area">

                        <!-- Start Clients brand logo -->
                        <div class="mu-clients-slider">

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/3bscientific.png" alt="Brand Logo" >
                            </div>

                            <div class="mu-clients-single" >
                                <img src="assets/images/sliderLogos/birr.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single" >
                                <img src="assets/images/sliderLogos/careofsweden.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/cellvu.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/esco.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/euromex.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/emedical.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/rt.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/gainternational.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/hunterscientific.png" alt="Brand Logo" >
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/implantech.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/invidia.png" alt="Brand Logo" >
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/kanmed.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/lifeinstruments.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/mesi.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/micromed.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/nico.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/nitrocare.png" alt="Brand Logo" >
                            </div>

                            <div class="mu-clients-single" >
                                <img src="assets/images/sliderLogos/orthoselect.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single" >
                                <img src="assets/images/sliderLogos/qualiteam.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/sechrist.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single" >
                                <img src="assets/images/sliderLogos/simex.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/soring.png" alt="Brand Logo" >
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/stryker.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single" >
                                <img src="assets/images/sliderLogos/thomson.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/unique.png" alt="Brand Logo">
                            </div>

                            <div class="mu-clients-single">
                                <img src="assets/images/sliderLogos/ysymedical.png" alt="Brand Logo">
                            </div>

                        </div>
                        <!-- End Clients brand logo -->

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Clients -->

</main>

<!-- End main content -->
