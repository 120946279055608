import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicesComponent } from './services/services.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { StaffComponent } from './staff/staff.component';
import { DivisionComponent } from './division/division.component';
import { ClientsComponent } from './clients/clients.component';
import { CareerComponent } from './career/career.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: '',   redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'about', component: AboutComponent },
  { path: 'staff', component: StaffComponent },
  { path: 'division', component: DivisionComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'career', component: CareerComponent },
  { path: 'contact', component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
