import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
declare function initializeAllComponents(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'STC';

  constructor(private router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { console.log('navigation end');initializeAllComponents();}
    });
  }

}
