<input type="hidden" id="menuSelect" value="clients">
<!-- Start Page Header area -->
<div id="mu-page-header" style="background-image: url(/assets/images/page-header-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mu-page-header-area">
                    <h1 class="mu-page-header-title">Our partners</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Header area -->

<!-- Start main content -->
<main>

    <!-- Start Portfolio -->
    <section id="mu-portfolio">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mu-portfolio-area">
                        <!-- Title -->
                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="mu-title">
                                    <h2>Our Recent Project</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa cum sociis.</p>
                                </div>
                            </div>
                        </div> -->

                        <div class="row">
                            <!-- Start Portfolio Filter -->
                            <!-- <div class="mu-portfolio-filter-area">
                                    <ul class="mu-simplefilter">
                                        <li class="active" data-filter="all">All<span>/</span></li>
                                        <li data-filter="1">U.S.A<span>/</span> </li>
                                        <li data-filter="2">Germany<span>/</span></li>
                                        <li data-filter="3">Sweden<span>/</span></li>
                                        <li data-filter="4">Denmark<span>/</span> </li>
                                        <li data-filter="5">Slovenia<span>/</span> </li>
                                        <li data-filter="6">France<span>/</span></li>
                                        <li data-filter="7">Italy<span>/</span></li>
                                        <li data-filter="8">U.K<span>/</span></li>
                                        <li data-filter="9">Netherlands<span>/</span></li>
                                        <li data-filter="10">Hungary</li>
                                    </ul>
                                </div> -->

                            <!-- Start Portfolio Content -->
                            <div class="mu-portfolio-content">
                                <div class="filtr-container">

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://www.3bscientific.com/" title="3B SCIENTIFIC" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/3BLogo_Name.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">3B SCIENTIFIC</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="9">
                                        <a href="https://birr.nl/" title="BIRR" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/23.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">BIRR BIOSCIENCES</h4>
                                                <h4 class="mu-filter-item-title">NETHERLANDS</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="3">
                                        <a href="https://www.careofsweden.com/" title="CARE of SWEDEN" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/16.svg"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">CARE of SWEDEN</h4>
                                                <h4 class="mu-filter-item-title">SWEDEN</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://cellvu.com/" title="MILLENNIUM SCIENCES" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/7.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">MILLENNIUM SCIENCES</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>


                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="4">
                                        <a href="https://www.esco-medical.com/" title="ESCO" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/17.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">ESCO</h4>
                                                <h4 class="mu-filter-item-title">DENMARK</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="9">
                                        <a href="https://www.euromex.com/en/" title="EUROMEX" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/22.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">EUROMEX MICROSCPES</h4>
                                                <h4 class="mu-filter-item-title">NETHERLANDS</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="8">
                                        <a href="https://www.excellentcare.co.uk/" title="EXCELLENTCARE MEDICAL"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/21.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">EXCELLENTCARE MEDICAL</h4>
                                                <h4 class="mu-filter-item-title">U.K</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>


                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="10">
                                        <a href="http://www.ffzrt.hu/index" title="FF Fémfeldolgozó Zrt."
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/25.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">FF Fémfeldolgozó Zrt.</h4>
                                                <h4 class="mu-filter-item-title">HUNGARY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://www.ga-international.com/" title="GA INTERNATIONAL"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/6.svg" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">GA INTERNATIONAL</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="9">
                                        <a href="https://www.hunterscientific.com/" title="HUNTER SCIENTIFIC"
                                            target="_blank">
                                            <img class="img-responsive"
                                                src="assets/images/portfolio/hunterscientific.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">HUNTER SCIENTIFIC</h4>
                                                <h4 class="mu-filter-item-title">---</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://www.implantech.com/" title="IMPLANTECH" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/3.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">IMPLANTECH</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="2">
                                        <a href="http://www.invidia-medical.de/" title="INVIDIA MEDICAL"
                                            target="_blank">
                                            <img class="img-responsive marginzero-img"
                                                src="assets/images/portfolio/11.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">INVIDIA MEDICAL</h4>
                                                <h4 class="mu-filter-item-title">GERMANY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="3">
                                        <a href="https://kanmed.se/" title="KANMED" target="_blank">
                                            <img class="img-responsive marginzero-img"
                                                src="assets/images/portfolio/15.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">KANMED</h4>
                                                <h4 class="mu-filter-item-title">SWEDEN</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://lifeinstruments.com/" title="LIFE INSTRUMENTS" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/8.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">LIFE INSTRUMENTS</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="5">
                                        <a href="https://www.mesimedical.com/" title="MESI, Ltd" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/18.svg"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">MESI, Ltd</h4>
                                                <h4 class="mu-filter-item-title">SLOVENIA</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="2">
                                        <a href="http://www.micromed.com/en-US/products/" title="MICROMED"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/13.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">MICROMED</h4>
                                                <h4 class="mu-filter-item-title">GERMANY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="http://www.niconeuro.com/nico-product-line/" title="NICO CORPORATION"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/5.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">NICO CORPORATION</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="6">
                                        <a href="https://www.nitrocare.com.tr/anasayfa/en" title="NITROCARE"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/nitrocare.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">NITROCARE</h4>
                                                <h4 class="mu-filter-item-title">---</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="2">
                                        <a href="https://ortho-select.com/en/home/" title="ORTHO-SELECT"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/10.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">ORTHO-SELECT</h4>
                                                <h4 class="mu-filter-item-title">GERMANY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="7">
                                        <a href="https://www.qualiteam.com/" title="QUALITEAM" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/20.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">QUALITEAM</h4>
                                                <h4 class="mu-filter-item-title">ITALY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://sechristusa.com/" title="SECHRIST" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/1.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">SECHRIST</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>


                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="2">
                                        <a href="https://simexmed.de/en/" title="SIMEX" target="_blank">
                                            <img class="img-responsive marginzero-img"
                                                src="assets/images/portfolio/9.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">SIMEX</h4>
                                                <h4 class="mu-filter-item-title">GERMANY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="2">
                                        <a href="https://www.soering.de/en/" title="SOERING" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/12.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">SOERING</h4>
                                                <h4 class="mu-filter-item-title">GERMANY</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://www.stryker.com/us/en/communications/products/berchtold-led-f-gen.html"
                                            title="STRYKER" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/2.png" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">STRYKER</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="1">
                                        <a href="https://thompsonsurgical.com/" title="THOMPSON SURGICAL"
                                            target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/4.svg" alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">THOMPSON SURGICAL</h4>
                                                <h4 class="mu-filter-item-title">U.S.A</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="9">
                                        <a href="https://www.uniquegroup.com/unique-division/unique-medical"
                                            title="EXCELLENTCARE MEDICAL" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/24.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">UNIQUE MEDICAL</h4>
                                                <h4 class="mu-filter-item-title">NETHERLANDS</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4 filtr-item img-block" data-category="6">
                                        <a href="http://ysy-medical.fr/" title="YSY MEDICAL" target="_blank">
                                            <img class="img-responsive" src="assets/images/portfolio/19.png"
                                                alt="image">
                                            <div class="mu-filter-item-content">
                                                <h4 class="mu-filter-item-title">YSY MEDICAL</h4>
                                                <h4 class="mu-filter-item-title">FRANCE</h4>
                                                <span class="fa fa-long-arrow-right"></span>
                                            </div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <!-- End Portfolio Content -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Portfolio -->

</main>

<!-- End main content -->