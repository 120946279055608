import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-mailer',
  templateUrl: './mailer.component.html',
  styleUrls: ['./mailer.component.css']
})

@Injectable({
  providedIn: "root"
})
export class MailerComponent implements OnInit {

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  sendEmail(url, data) {
    return this.http.post(url, data);
  }

}
