<input type="hidden" id="menuSelect" value="division">
<!-- Start Page Header area -->
<div id="mu-page-header" style="background-image: url(/assets/images/divisions-header-bg.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mu-page-header-area">
                    <h1 class="mu-page-header-title">Divisions</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Header area -->

<!-- Start main content -->
<main>

    
    <!-- Start division 1 -->
    <div id="mu-video">
        <div class="row">
            <div class="col-md-6">
                <div class="mu-video-left">
                    <img class="img-responsive" src="assets/images/division/division1.jpg" alt="image">
                </div>
            </div>
            <div class="col-md-6">
                <div class="mu-video-right">
                    <div>
                    Medical and Scientific Equipment Division
                    </div>
                    <p>
                    Thanks to its highly experienced administration, the medical and scientific equipment division of STC has started from where others have 
                    ended and got the reputation of being provider for niche products creating a market need for it or fulfilling partially or unsatisfied market need.
                    The selection of our products is done in association with the key opinion leaders and decision makers at various medical specialties.
                    </p>
                </div>
            </div>
        </div>

    </div>
    <!-- End division 1 -->

    <!-- Start division 2 -->
    <div id="mu-video">
        <div class="row">
            <div class="col-md-6">
                <div class="mu-video-right">
                    <div>
                    Laboratory Supplies Division
                    </div>
                    <p>
                    Laboratory Division is specialized in handling Clinical Laboratory Projects right from Design, Engineering to project Execution, 
                    Installation and Commissioning. Our laboratory division represents leading Diagnostic Global leaders in Clinical and Molecular 
                    diagnostics. We supply POCT technology to physicians and MOH and applied science instrumentation and platforms to MOH as well as research institutes.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mu-video-left">
                    <img class="img-responsive" src="assets/images/division/division2.jpg" alt="image">
                </div>
            </div>
        </div>

    </div>
    <!-- End division 2 -->

    <!-- Start division 3 -->
    <div id="mu-video">
        <div class="row">
            <div class="col-md-6">
                <div class="mu-video-left">
                    <img class="img-responsive" src="assets/images/division/division3.png" alt="image">
                </div>
            </div>
            <div class="col-md-6">
                <div class="mu-video-right">
                    <div>
                    IVF Assisted Reproduction Division
                    </div>
                    <p>
                    We have qualified expertise on board to design and commission full-fledged IVF 
                    laboratories including complete range of equipment and disposables. 
                    We also provide IVF consultation services to the private sector.
                    </p>
                </div>
            </div>
        </div>

    </div>
    <!-- End division 3 -->

    <!-- Start division 4 -->
    <div id="mu-video">
        <div class="row">
            <div class="col-md-6">
                <div class="mu-video-right">
                    <div>
                    Logistics & Warehousing Department
                    </div>
                    <p>
                    We have an excellent distribution network for our products and services to various customers 
                    in Kuwait which include Hospitals, Medical Centers and Pharmacies. Our logistic team has vast experience in supply chain management.
                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mu-video-left">
                    <img class="img-responsive" src="assets/images/division/division4.jpg" alt="image">
                </div>
            </div>
        </div>

    </div>
    <!-- End division 4 -->


</main>

<!-- End main content -->