<!-- Start Page Header area -->
<div id="mu-page-header">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mu-page-header-area">
                    <h1 class="mu-page-header-title">STC Staffs</h1>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Header area -->

<!-- Start main content -->
<main>

    
    <!-- Start division 1 -->
    <div class="staff-content">
        <div class="row">
            <div class="col-md-12">
                <div class="icon-div">
                    <img class="img-responsive" src="/assets/images/staff/ourstaff.png" alt="image" width='200'>
                </div>
                <div class="staff-heading">
                Our Staffs
                </div>
                <div class="staff-subtext">
                <p>
                STC is staffed with professional resources with diversified backgrounds :  
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Medical 
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Biomedical Engineering 
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Sales & Product Representatives 
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Technical & Application Specialists  
                <img class="img-responsive" src="/assets/images/staff/orangearrowright.jpg" width='20'> Administration  
                </p>
                <p>
                Extensive market knowledge and network of relations with key decision makers in the healthcare industry
                </p>
                <p>
                Our staff receives training and orientation about NMT Policies & Procedures, Business Ethics Manual, 
                Job Expectations, Sales, and Customer Relationship Management. In addition to staff training on new products technologies
                </p>
                <p>
                Continuously follow-up business files, orders, and visits to end-users. 
                </p>
                </div>
                
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                
                <div class="icon-div">
                    <img class="img-responsive" src="/assets/images/staff/ourstaff.png" alt="image" width='200'>
                </div>
                <div class="staff-heading">
                Sales Department
                </div>
                <div class="staff-subtext">
                <p>
                Our sales department is run by experienced and qualified medical and scientific specialists who are well aware of market 
                requirements and maintain excellent customer 
                relations to promote the different medical, laboratory and scientific products of the companies we represent exclusively 
                </p>
                </div>
                
                
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12">
                <div class="icon-div">
                    <img class="img-responsive" src="/assets/images/staff/ourstaff.png" alt="image" width='200'>
                </div>
                <div class="staff-heading">
                Technical Service Centre
                </div>
                <div class="staff-subtext">
                <p>
                We have fully equipped technical service centre for after sales services 
                which is run by our well qualified, experienced and trained Biomedical service engineers
                </p>
                <p>
                We would like to clarify that Kuwait is geographically small and the demand in terms of quantities 
                are limited. As it is shown on the following list, we are representing a many company in order to 
                offer complete systems and solutions to our customers
                </p>
                <p>
                We are the accredited exclusive agents for many world-renowned companies for their various products
                </p>
                </div>
                
            </div>
        </div>

    </div>
    <!-- End division 1 -->


</main>

<!-- End main content -->	