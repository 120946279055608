import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MailerComponent } from "../mailer/mailer.component";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

declare var bootbox:any;

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {

  constructor(public mailer: MailerComponent,private router:Router,private spinner: NgxSpinnerService) { }

  formdata;
  nameControl;
  emailControl;
  mobileControl;
  messageControl;
  resumeControl;
  resumeTControl;

  ngOnInit(): void {
    this.formdata = new FormGroup({
        name: this.nameControl = new FormControl("",[
             Validators.required
           ]),
        email: this.emailControl = new FormControl("",[
          Validators.required,
          Validators.email
        ]),
        mobile: this.mobileControl = new FormControl("",[
          Validators.required
        ]),
        message: this.messageControl = new FormControl("",[
          Validators.required
        ]),
        resume: this.resumeControl = new FormControl("",[
          Validators.required
        ]),
        resumeT: this.resumeTControl = new FormControl("",[]),
        countryCode: new FormControl("965",[])
     });
  }

  fileName;
  fileValue;
  fileSize = 0;
  filecontent;

  onFileChange(event) {
    if(event.target.files.length > 0) 
     {
       this.fileName = event.target.files[0].name;
       this.fileValue = event.target.files[0];
       this.fileSize = event.target.files[0].size;
     }
   }

  sendCareerMail(data) {
    if(this.formdata.valid){
      let bufferVal = new ArrayBuffer(this.fileSize);
      var promise = Promise.resolve();
      //this.file = promise.then(()=> this.callMailSender(this.fileValue));
      this.callFileConvert(this.fileValue).then(pdata => 
        this.callMailSender(data,pdata)
        );
    }else{
      return;
      // Object.keys(this.formdata.controls).forEach(key => {

      //   const controlErrors: ValidationErrors = this.formdata.get(key).errors;
      //   if (controlErrors != null) {
      //         Object.keys(controlErrors).forEach(keyError => {
      //           console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      //         });
      //       }
      //     });
    }
    
  }

  callMailSender(data,pdata){
    this.spinner.show();
    let contact = {
      name: data.name,
      email: data.email,
      mobile: '+'+ data.countryCode + ' ' + data.mobile,
      message: data.message,
      filename: this.fileName,
      filedata: pdata,
      selectOpt: 'career'
    }
    var prtcl = window.location.href;
    var prtclArr = prtcl.split(":");
    this.mailer.sendEmail(prtclArr[0]+"://stc-kw.com/app/sendmail", contact).subscribe(
      data => {
        let res:any = data; 
        this.spinner.hide();
        //alert("Message Sent Successfully");
        bootbox.alert({
          message: "Message Sent Successfully!"
        });
        this.formdata.reset(); 
        //this.router.navigate(['/home']);
      },
      err => {
        console.log(err);
      });
  }

  callFileConvert(filecontent){
    return new Promise((resolve, reject) => {
      var fr = new FileReader();  
      fr.readAsArrayBuffer(filecontent);
      fr.onloadend = () => {
        var arrayval = fr.result;
        //let blob = new Blob([new Uint8Array(arrayval as Iterable<number>)], {type: filecontent.type });
         var array = new Uint8Array(arrayval as Int8Array);
         //var binaryString = String.fromCharCode.apply(null, array);
        //console.log(array);
        resolve(array);
      };
      
    });
    // let stringVal = '';
    // var reader = new FileReader();
    // reader.onload = function (event) {
    //   stringVal = event.target.result as string;
    //   resolve;
    // }
    // reader.readAsArrayBuffer(file);
  }

}
