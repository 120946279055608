<input type="hidden" id="menuSelect" value="services">
<!-- Start Page Header area -->
<div id="mu-page-header" style="background-image: url(/assets/images/services-header-bg.jpg);">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="mu-page-header-area">
                  <h1 class="mu-page-header-title">Services</h1>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- End Page Header area -->

<!-- Start main content -->
<main>
		
<!-- Start Pricing Table -->
<section id="mu-pricing">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="mu-pricing-area">
          <!-- Title -->
          <div class="row">
            <div class="col-md-12">
              <div class="mu-title">
                <h2>What we offer</h2>
                <p>Importing, marketing and sales of healthcare complete solutions and a wide range of hospital, medical & diagnostic equipment and supplies.</p>
                <p>Excellent round-the-clock after-sales service and maintenance</p>
              </div>
            </div>
            <div class="col-md-12">
              <div class="mu-pricing-content">
                <ul class="mu-pricing-table">
                  <li>
                    <div class="mu-pricing-single">
                      <div class="mu-pricing-single-icon">
                        <span class="fa fa-heartbeat"></span>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Surgery</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Cardiology</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Neurosurgery</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Orthopedics</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Dermatology</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Gynecology</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Dialysis</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Dental</h4>
                      </div>
                      <!-- <div class="mu-pricing-single-content">
                        <ul>
                          <li>Cardiology</li>
                          <li>Neurosurgery</li>
                          <li>Orthopedics</li>
                          <li>Dermatology</li>
                          <li>Gynecology</li>
                          <li>Dialysis</li>
                          <li>Dental</li>
                        </ul>
                      </div> -->
                      <!--<div class="mu-single-pricebox">
                        <h4>29$<span>/month</span></h4>
                      </div>
                      <a class="mu-buy-now-btn" href="#">Buy now</a>-->
                    </div>
                  </li>
                  <li>
                      <div class="mu-pricing-single">
                        <div class="mu-pricing-single-icon">
                          <span class="fa fa-stethoscope"></span>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Urology</h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>ICU</h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Anesthesia</h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Pediatrics
                            </h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Ophthalmology
                            </h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Gastroenterology
                            </h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Neurology</h4>
                        </div>
                        <div class="mu-pricing-single-title">
                          <h4>Morgue</h4>
                        </div>
                        <!-- <div class="mu-pricing-single-content">
                          <ul>
                            <li>ICU</li>
                            <li>Anesthesia</li>
                            <li>Pediatrics</li>
                            <li>Ophthalmology</li>
                            <li>Gastroenterology</li>
                            <li>Neurology</li>
                            <li>Morgue</li>
                          </ul>
                        </div> -->
                        <!--<div class="mu-single-pricebox">
                          <h4>29$<span>/month</span></h4>
                        </div>
                        <a class="mu-buy-now-btn" href="#">Buy now</a>-->
                      </div>
                  </li>
                  <li>
                    <div class="mu-pricing-single">
                      <div class="mu-pricing-single-icon">
                        <span class="fa fa-medkit"></span>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Laboratory</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>IVF</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Medical Imaging</h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>
                          ENT
                          </h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Wound Care
                          </h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Oncology
                          </h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>CSSD
                          </h4>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h4>Turnkey Projects</h4>
                      </div>
                      
                      <!-- <div class="mu-pricing-single-content">
                        <ul>
                          <li>ICU</li>
                          <li>Medical Imaging</li>
                          <li>ENT</li>
                          <li>Wound Care</li>
                          <li>Oncology</li>
                          <li>CSSD</li>
                          <li>Turnkey Projects</li>
                        </ul>
                      </div> -->
                      <!--<div class="mu-single-pricebox">
                        <h4>29$<span>/month</span></h4>
                      </div>
                      <a class="mu-buy-now-btn" href="#">Buy now</a>-->
                    </div>
                  </li>
                  <!-- <li>
                    <div class="mu-pricing-single">
                      <div class="mu-pricing-single-icon">
                        <span class="fa fa-medkit"></span>
                      </div>
                      <div class="mu-pricing-single-title">
                        <h3>Services</h3>
                      </div>
                      <div class="mu-pricing-single-content">
                        <ul>
                          <li><span class="seperator-class">></span> Importing, marketing and sales of healthcare complete solutions and a wide range of hospital, medical & diagnostic equipment and supplies.</li>
                          <li><br></li>
                          <li><span class="seperator-class">></span> Excellent round-the-clock after-sales service and maintenance.</li>
                        </ul>
                      </div>
                      <div class="mu-single-pricebox">
                        <h4>29$<span>/month</span></h4>
                      </div>
                      <a class="mu-buy-now-btn" href="#">Buy now</a>
                    </div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Pricing Table -->

  <!-- Start About -->
  <section id="mu-about" style="box-shadow: 0px 0px 14px #ededed;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="mu-about-area">
            <!-- Start Feature Content -->
            <div class="row">
              <div class="col-md-6">
                <div class="mu-about-left">
                  <img class="" src="/assets/images/services1.png" alt="img">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mu-about-right">
                    <h2>Major Customers / Ministries</h2>
                    <ul>
                        <li> Health (MOH), Defense (MOD), Interior (MOI), Education (MOE). </li>
                        <li> Hospitals – General & Specialized. </li>
                        <li> Polyclinics & Medical Specialized Centres. </li>
                        <li> & Medical Laboratories. </li>
                        <li> Universities & Educational Institutions. </li>
                        <li> & Research centres. </li>
                        <li> Kuwait Oil Company (KOC). </li>
                        <li> & Petrochemical companies. </li>
                        <li> Healthcare institutions. </li>
                        <li> & Banks & Financial Institutions. </li>
                    </ul>
                </div>
              </div>
            </div>
            <!-- End Feature Content -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About -->

  <!-- Goals -->
<!--
  <section id="mu-about">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mu-about-goal">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mu-title-about">
                                <h2>Major Customers / Ministries</h2>
                                <ul class="text-pad-50 mu-about-p">
                                    <li><p> Health (MOH), Defense (MOD), Interior (MOI), Education (MOE). </p></li>
                                    <li><p> Hospitals – General & Specialized. </p></li>
                                    <li><p> Polyclinics & Medical Specialized Centres. </p></li>
                                    <li><p> Medical Laboratories. </p></li>
                                    <li><p> Universities & Educational Institutions. </p></li>
                                    <li><p> Research centres. </p></li>
                                    <li><p> Kuwait Oil Company (KOC). </p></li>
                                    <li><p> Petrochemical companies. </p></li>
                                    <li><p> Healthcare institutions. </p></li>
                                    <li><p> Banks & Financial Institutions. </p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
  <!-- Start Clients -->
  <div id="mu-clients">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mu-clients-area">

                    <!-- Start Clients brand logo -->
                    <div class="mu-clients-slider">

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/3bscientific.png" alt="Brand Logo" >
                        </div>

                        <div class="mu-clients-single" >
                            <img src="assets/images/sliderLogos/birr.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single" >
                            <img src="assets/images/sliderLogos/careofsweden.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/cellvu.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/esco.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/euromex.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/emedical.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/rt.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/gainternational.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/hunterscientific.png" alt="Brand Logo" >
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/implantech.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/invidia.png" alt="Brand Logo" >
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/kanmed.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/lifeinstruments.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/mesi.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/micromed.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/nico.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/nitrocare.png" alt="Brand Logo" >
                        </div>

                        <div class="mu-clients-single" >
                            <img src="assets/images/sliderLogos/orthoselect.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single" >
                            <img src="assets/images/sliderLogos/qualiteam.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/sechrist.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single" >
                            <img src="assets/images/sliderLogos/simex.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/soring.png" alt="Brand Logo" >
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/stryker.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single" >
                            <img src="assets/images/sliderLogos/thomson.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/unique.png" alt="Brand Logo">
                        </div>

                        <div class="mu-clients-single">
                            <img src="assets/images/sliderLogos/ysymedical.png" alt="Brand Logo">
                        </div>

                    </div>
                    <!-- End Clients brand logo -->

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Clients -->